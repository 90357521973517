




import { Api, SuperUserSchoolSearchResult } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  name: 'GG4LAddOrg'
})
export default class GG4LAddOrg extends Mixins(ComponentHelper) {
  @Prop({ required: true }) school!: SuperUserSchoolSearchResult

  mounted () {
    const p = prompt('Enter Third Party ID')
    if (p) {
      this.linkExistingSchool(p)
    } else {
      this.$emit('cancel')
    }
  }

  async linkExistingSchool (id: string) {
    try {
      if (this.school.one_roster_id) {
        throw new Error('School already linked')
      }

      await Api.linkSchoolToOneRosterOrg(this.school.id, id, 'gg4l', null)

      this.$emit('linked', id)
    } catch (err) {
      if (err instanceof Error) {
        this.toastError(err.message)
      } else {
        console.log(err)
      }
    }
  }
}
