<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Mark Paid
        </p>
        <button class="delete" aria-label="close" @click="close" />
      </header>
      <section class="modal-card-body">
        <b-field label="Method">
          <b-select v-model="method" placeholder="Select a name">
            <option value="cheque">
              Cheque
            </option>
            <option value="bacs">
              BACS
            </option>
            <option value="card">
              Card
            </option>
            <option value="other">
              Other
            </option>
          </b-select>
        </b-field>

        <b-field label="Payment date">
          <b-datepicker
            v-model="date"
            inline
            placeholder="Click to select..."
            icon="calendar-today"
          />
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="submit">
          Submit
        </button>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MarkPaidModal',
  props: ['invoiceId'],
  data () {
    return {
      method: 'cheque',
      date: new Date()
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('success', this.invoiceId, this.method, this.date)
    }
  }
}
</script>
