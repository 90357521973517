






























































































































import { Api, ClassLinkTenantInfo, CountryCode, CountryInfo, Locale, OneRosterOrg, SchoolModel } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'ClassLinkAddOrg'
})
export default class ClassLinkAddOrg extends Mixins(ComponentHelper) {
  @Prop({ required: true }) school!: SchoolModel

  selectedDistrict: SchoolModel | null = null

  selectedTenant: ClassLinkTenantInfo | null = null

  tenants: ClassLinkTenantInfo[] = []

  children: OneRosterOrg[] = []

  childrenLoaded: boolean = false

  orgToImport: OneRosterOrg | null = null

  newOrgLocale: Locale | null = null

  newOrgCountryCode: CountryCode | null = null

  countriesData: CountryInfo[] = []

  loading: boolean = false

  step: number = 0

  toastContainer = '#classLinkAddOrg'

  @Watch('step')
  stepChanged (newVal: number, oldVal: number) {
    if (newVal === 1 && !this.childrenLoaded) {
      this.loadTenantChildren()
    }

    if (newVal === 0 && oldVal > 0) {
      this.childrenLoaded = false
      this.children = []
    }
  }

  async mounted () {
    if (this.school.org_type === 'district') {
      // linking up district for first time
      if (!this.school.one_roster_tenant_id) {
        this.step = 0
      } else {
        // district already linked - set it as tenant
        this.selectedTenant = { one_roster_tenant_id: this.school.one_roster_tenant_id, name: this.school.school_name }
        this.selectedDistrict = this.school
        this.step = 1
      }
    } else {
      // does the school have a district? - if so, limit to that school's district tenant
      if (this.school.district_id) {
        const district = await Api.getSchool(this.school.district_id)

        if (district.one_roster_tenant_id) {
          this.selectedDistrict = district
          this.selectedTenant = { one_roster_tenant_id: district.one_roster_tenant_id, name: district.school_name }

          this.step = 1
        } else {
          this.toastError('This school\'s district must be linked to One Roster first')

          this.$emit('quit')
          return
        }
      } else {
        this.step = 0
      }
    }

    this.getClassLinkTenants()
    this.loadCountryData()
  }

  get shouldLinkToExisting () {
    if (this.school.org_type === 'district' && !this.school.one_roster_tenant_id) {
      return true
    } else if (this.school.org_type !== 'district') {
      return true
    }

    return false
  }

  chooseClassLinkTenant (tenant: ClassLinkTenantInfo) {
    this.selectedTenant = tenant

    this.step = 1
  }

  startImportingNewOrg (orgToImport: OneRosterOrg) {
    this.orgToImport = orgToImport

    this.step = 2
  }

  async getClassLinkTenants () {
    this.loading = true

    try {
      this.tenants = await Api.getClassLinkTenants()
    } catch (err) {
      if (err instanceof Error) {
        this.toastError(err.message)
      } else {
        console.log(err)
      }
    } finally {
      this.loading = false
    }
  }

  async loadCountryData () {
    this.loading = true

    try {
      this.countriesData = await Api.getCountries()
    } catch (err) {
      if (err instanceof Error) {
        this.toastError(err.message)
      } else {
        console.log(err)
      }
    } finally {
      this.loading = false
    }
  }

  async refreshClassLinkTenants () {
    this.loading = true

    try {
      await Api.refreshClassLinkTenants()
      await Api.getClassLinkTenants()
    } catch (err) {
      if (err instanceof Error) {
        this.toastError(err.message)
      } else {
        console.log(err)
      }
    } finally {
      this.loading = false
    }
  }

  async loadTenantChildren () {
    this.loading = true

    try {
      if (!this.selectedTenant) {
        throw new Error('Tenant not selected')
      }

      this.children = await Api.getClassLinkOrgsForTenant(this.selectedTenant.one_roster_tenant_id)
      this.childrenLoaded = true
    } catch (err) {
      if (err instanceof Error) {
        this.toastError(err.message)
      } else {
        console.log(err)
      }
    } finally {
      this.loading = false
    }
  }

  async linkExistingSchool (oneRosterOrg: OneRosterOrg) {
    this.loading = true

    try {
      if (this.school.one_roster_id) {
        throw new Error('School already linked')
      }

      if (!this.selectedTenant) {
        throw new Error('Tenant not selected')
      }

      await Api.linkSchoolToOneRosterOrg(this.school.id, oneRosterOrg.sourcedId, 'class-link', this.selectedTenant.one_roster_tenant_id)

      this.$emit('linked', { id: oneRosterOrg.sourcedId, tenant_id: this.selectedTenant.one_roster_tenant_id })
    } catch (err) {
      if (err instanceof Error) {
        this.toastError(err.message)
      } else {
        console.log(err)
      }
    } finally {
      this.loading = false
    }
  }

  async importOneRosterSchool () {
    this.loading = true

    try {
      if (!this.orgToImport) {
        throw new Error('One Roster org not selected')
      }

      if (!this.selectedTenant) {
        throw new Error('Tenant not selected')
      }

      if (!this.newOrgLocale) {
        throw new Error('Locale not selected')
      }

      if (!this.newOrgCountryCode) {
        throw new Error('Country not selected')
      }

      if (!this.selectedDistrict?.one_roster_id) {
        throw new Error('District not imported')
      }

      await Api.createSchoolFromOneRoster('class-link', { id: this.orgToImport.sourcedId, tenant_id: this.selectedTenant.one_roster_tenant_id, locale: this.newOrgLocale, country_code: this.newOrgCountryCode, district_id: this.selectedDistrict.one_roster_id })

      this.$emit('created')
    } catch (err) {
      if (err instanceof Error) {
        this.toastError(err.message)
      } else {
        console.log(err)
      }
    } finally {
      this.loading = false
    }
  }
}
