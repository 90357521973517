








































































































































































































































































import { Api, CountryInfo, getPriceForPlan, getVolumeUnitPrice, StripeInvoiceBillingReason, Subscription, SubscriptionPlanInfo, SubscriptionProductInfo, SubscriptionProductScopeInfo, SuperUserSchoolSearchResult } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { UnreachableCaseError } from 'ts-essentials'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import _ from 'lodash'
import PriceTiers from '@/edshed-common/components/PriceTiers.vue'
import Stripe from 'stripe'
import { sleep } from '@/edshed-common/utils'

type MenuProductOption = {
  type: 'product',
  data: SubscriptionProductInfo
  children: MenuScopeOption[]
}

type MenuScopeOption = {
  type: 'scope',
  data: SubscriptionProductScopeInfo
  children: MenuRegionOption[]
}

type MenuRegionOption = {
  type: 'region',
  data: 'domestic' | 'international'
  children: MenuPlanOption[]
}

type MenuPlanOption = {
  type: 'plan',
  data: SubscriptionPlanInfo
  base: SubscriptionPlanInfo
  addOns: SubscriptionPlanInfo[]
}

@Component({
  name: 'AddManualInvoice',
  components: { PriceTiers }
})
export default class AddManualInvoice extends Mixins(ComponentHelper) {
  @Prop({ required: true }) sub!: Subscription

  @Prop({ required: true }) school!: SuperUserSchoolSearchResult

  type: StripeInvoiceBillingReason = 'subscription_cycle'

  collectionMethod: Stripe.Invoice.CollectionMethod = 'charge_automatically'

  lines: { plan: SubscriptionPlanInfo, description: string, qty: number, tax_rate: number }[] = []

  countries: CountryInfo[] = []

  plans: SubscriptionPlanInfo[] = []

  coupons: Stripe.Coupon[] = []

  coupon: Stripe.Coupon | null = null

  @Watch('planPicker')
  planPickerChanged (val: boolean) {
    if (val) {
      this.toastContainer = '#plan-picker-root'
    } else {
      this.toastContainer = '#manual-invoice-root'
    }
  }

  showPlanPicker: boolean = false

  showCouponPicker: boolean = false

  selectedProduct: SubscriptionProductInfo | null = null

  preventEmail: boolean = false

  loading: boolean = false

  toastContainer = '#manual-invoice-root'

  async mounted () {
    const country = this.getCountries()
    const plan = this.getPlans()
    const coupon = this.getCoupons()

    await Promise.all([country, plan, coupon])

    this.$nextTick(() => {
      this.lines = this.guessedLines
    })

    if (this.allowInvoice) {
      this.collectionMethod = 'send_invoice'
    }
  }

  generateTaxMessage (taxRate: number) {
    let rate = 0

    if (!taxRate) {
      return ''
    }

    rate = taxRate

    return `${(rate * 100 - 100).toFixed(2)}%`
  }

  unitPrice (line: { plan: SubscriptionPlanInfo, description: string, qty: number, tax_rate: number }) {
    switch (line.plan.price_type) {
      case 'fixed':
        return line.plan.unit_amount!
      case 'volume':
        const qty = Math.abs(line.qty)
        return qty === 0 ? 0 : getVolumeUnitPrice(qty, line.plan.price_tiers!)
      case 'graduated':
        return 'N/A'
      default:
        throw new UnreachableCaseError(line.plan.price_type)
    }
  }

  taxForLine (line: { plan: SubscriptionPlanInfo, description: string, qty: number, tax_rate: number }) {
    const priceWithDiscounts = this.discountedPriceForLine(line)

    return (line.tax_rate - 1) * priceWithDiscounts
  }

  priceForLine (line: { plan: SubscriptionPlanInfo, description: string, qty: number, tax_rate: number }) {
    if (line.qty === 0) {
      return 0
    } else if (line.qty < 0) {
      const absValue = -1 * line.qty
      return getPriceForPlan(absValue, line.plan) * -1
    } else {
      return getPriceForPlan(line.qty, line.plan)
    }
  }

  discountedPriceForLine (line: { plan: SubscriptionPlanInfo, description: string, qty: number, tax_rate: number }) {
    const planPrice = getPriceForPlan(line.qty, line.plan)

    let priceWithDiscounts = planPrice

    if (this.coupon?.amount_off) {
      const ratio = (this.coupon.amount_off) / (100 * this.subtotal)
      priceWithDiscounts = Math.max(planPrice - (100 * planPrice * ratio) / 100, 0)
    }

    if (this.coupon?.percent_off) {
      priceWithDiscounts = Math.floor(planPrice * (100 - this.coupon.percent_off)) / 100
    }

    return priceWithDiscounts
  }

  scopesForProduct (product: SubscriptionProductInfo) {
    return this.scopes.filter(s => s.product_id === product.id)
  }

  plansForScope (scope: SubscriptionProductScopeInfo) {
    return this.plans.filter(p => p.scope_id === scope.id)
  }

  domesticPlansForScope (scope: SubscriptionProductScopeInfo) {
    return this.plansForScope(scope).filter(p => p.domestic)
  }

  intlPlansForScope (scope: SubscriptionProductScopeInfo) {
    return this.plansForScope(scope).filter(p => !p.domestic)
  }

  async addLineClicked () {
    this.loading = true
    await sleep(50)
    this.$nextTick(() => {
      this.showPlanPicker = true
      this.$nextTick(() => {
        this.loading = false
      })
    })
  }

  async addCouponClicked () {
    this.loading = true
    await sleep(50)
    this.$nextTick(() => {
      this.showCouponPicker = true
      this.$nextTick(() => {
        this.loading = false
      })
    })
  }

  setCoupon (coupon: Stripe.Coupon) {
    this.showCouponPicker = false
    this.coupon = coupon
  }

  removeCoupon () {
    this.coupon = null
  }

  productClicked (expanded: boolean, product: SubscriptionProductInfo) {
    if (expanded) {
      this.selectedProduct = product
    }
  }

  addPlanToInvoice (plan: SubscriptionPlanInfo) {
    this.lines.push({
      description: plan.product.title,
      plan,
      qty: 1,
      tax_rate: this.guessedTax
    })

    this.showPlanPicker = false
  }

  removeLine (idx: number) {
    console.log({ idx })
    this.lines.splice(idx, 1)
  }

  get scopesAvailable () {
    return this.scopes.filter(s => s.product_id === this.selectedProduct?.id)
  }

  get subtotal (): number {
    return this.lines.reduce((sub, l) => sub + this.priceForLine(l), 0)
  }

  get subtotalAfterDiscount (): number {
    if (!this.coupon) {
      return this.subtotal
    }

    return this.lines.reduce((sub, l) => {
      const priceWithDiscounts = this.discountedPriceForLine(l)

      return sub + priceWithDiscounts
    }, 0)
  }

  get discount (): number {
    if (this.coupon === null) {
      return 0
    }

    return this.subtotal - this.subtotalAfterDiscount
  }

  get tax () {
    return this.lines.reduce((sub, l) => sub + this.taxForLine(l), 0)
  }

  get total () {
    return this.subtotalAfterDiscount + this.tax
  }

  get country () {
    return this.countries.find(c => c.code === this.school.school_country_code)
  }

  get guessedTax () {
    if (!this.country) {
      return 1
    }

    let rate = this.country.tax_rate
    if (this.country.reverse_charge && this.school.vat_number && this.school.vat_number !== '') {
      rate = 1
    }

    return rate
  }

  get guessedLines (): { plan: SubscriptionPlanInfo, description: string, qty: number, tax_rate: number }[] {
    return [
      {
        plan: this.sub.base_plan,
        qty: this.guessedQuantity,
        tax_rate: this.guessedTax,
        description: this.sub.base_plan.product.title
      },
      ...this.sub.add_on_plans.map(p => ({
        plan: p,
        qty: this.guessedQuantity,
        tax_rate: this.guessedTax,
        description: p.product.title
      }))
    ]
  }

  get guessedQuantity () {
    switch (this.meteredEntity) {
      case 'pupil':
        return this.sub.pupil_seats ?? 1
      case 'teacher':
        return this.sub.teacher_seats ?? 1
      case 'class':
        return this.sub.classes ?? 1
      case null:
        return 1
      default:
        throw new UnreachableCaseError(this.meteredEntity)
    }
  }

  get meteredEntity () {
    return this.sub.base_plan.metered_entity
  }

  get products () {
    const uniqueProducts = new Map<number, SubscriptionProductInfo>()

    for (const plan of this.plans) {
      const keyValue = plan.product_id

      if (!uniqueProducts.has(keyValue)) {
        uniqueProducts.set(keyValue, plan.product)
      }
    }

    return Array.from(uniqueProducts.values())
  }

  get scopes () {
    const uniqueScopes = new Map<number, SubscriptionProductScopeInfo>()

    for (const plan of this.plans) {
      const keyValue = plan.scope_id

      if (!uniqueScopes.has(keyValue)) {
        uniqueScopes.set(keyValue, plan.scope)
      }
    }

    return Array.from(uniqueScopes.values())
  }

  get menu (): MenuProductOption[] {
    return this.products.map(p => ({
      data: p,
      type: 'product',
      children: this.scopesForProduct(p).map((s) => {
        const domesticPlans = this.domesticPlansForScope(s)
        const intlPlans = this.intlPlansForScope(s)

        const children: MenuRegionOption[] = []

        if (domesticPlans.length) {
          children.push({
            type: 'region',
            data: 'domestic',
            children: domesticPlans.filter(p => p.parent_id === null).map((p) => {
              const base = p
              const addOns = domesticPlans.filter(p2 => p2.parent_id === p.id)
              return {
                type: 'plan',
                base,
                addOns,
                data: p
              }
            })
          })
        }

        if (intlPlans.length) {
          children.push({
            type: 'region',
            data: 'international',
            children: intlPlans.filter(p => p.parent_id === null).map((p) => {
              const base = p
              const addOns = intlPlans.filter(p2 => p2.parent_id === p.id)
              return {
                type: 'plan',
                base,
                addOns,
                data: p
              }
            })
          })
        }

        return {
          type: 'scope',
          data: s,
          children
        }
      })
    }))
  }

  get allowInvoice () {
    return this.school.org_type === 'school' || this.school.org_type === 'district'
  }

  @Watch('canSave')
  canSaveChanged (val: boolean) {
    this.$emit('canSave', val)
  }

  get canSave () {
    if (this.total <= 0) {
      return false
    }

    if (this.lines.length === 0) {
      return false
    }

    return true
  }

  async getCountries () {
    try {
      this.countries = await Api.getCountries()
    } catch (err) {
      this.toastError('Could not load country data')
    }
  }

  async getPlans () {
    try {
      this.plans = await Api.getSubscriptionPlans({ currency: this.sub.currency }, undefined)
    } catch (err) {
      this.toastError('Could not load plan data')
    }
  }

  async getCoupons () {
    try {
      this.coupons = await Api.getCoupons()
      this.coupons = this.coupons.filter(c => c.valid)
    } catch (err) {
      this.toastError('Could not save invoice')
    }
  }

  async createInvoice () {
    try {
      if (this.sub.subscription_id === null) {
        throw new Error('Not a Stripe subscription')
      }

      this.loading = true

      const invoice = await Api.postManualStripeInvoice(this.sub.subscription_id, {
        collectionMethod: this.collectionMethod,
        reason: this.type,
        coupon: this.coupon?.id ?? null,
        preventEmail: this.preventEmail,
        lines: this.lines.map(l => ({
          description: l.description === l.plan.product.title ? null : l.description,
          planStripeId: l.plan.stripe_id,
          quantity: l.qty,
          taxRate: l.tax_rate,
          unitAmountDecimal: (100 * this.priceForLine(l) / l.qty).toFixed(6)
        }))
      })

      this.$emit('invoice-created', invoice)
    } catch (err) {
      this.toastError('Could not save invoice')
    } finally {
      this.loading = false
    }
  }
}
