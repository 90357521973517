































import Vue from 'vue'
import Component from 'vue-class-component'
import { Api, FileAttachment } from '@/edshed-common/api'
import FileInput from './FileInput.vue'

@Component({ components: {}, props: ['invoice', 'file', 'date', 'userUploaded'] })
export default class UploadRemittanceModal extends Vue {
  private _date: Date = new Date()

  private record: FileAttachment = { name: '', description: '' }

  public mounted () {
    if (this.$props.file) {
      this.record = this.$props.file
    }
  }

  public async submit () {
    if (!this.record.new_file && !this.record.file) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Please upload a file',
        position: 'is-bottom',
        type: 'is-danger'
      })

      return
    }

    if (this.record.new_file && !this.record.file) {
      try {
        await Api.uploadRemittance(this.$props.invoice.invoice_id, this.$data._date, this.record.new_file)
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Saved',
          position: 'is-top',
          type: 'is-success'
        })
        this.$emit('uploaded')
        this.$emit('close')
      } catch (err: unknown) {
        if (err instanceof Error) {
          this.$buefy.toast.open({
            duration: 5000,
            message: err.message,
            position: 'is-bottom',
            type: 'is-danger'
          })
        }
      }

      return
    }

    if (this.record.file) {
      try {
        await Api.updateRemittance(this.$props.invoice.invoice_id, this.$data._date, this.record.new_file || undefined)
        this.$buefy.toast.open({
          duration: 5000,
          message: 'Saved',
          position: 'is-top',
          type: 'is-success'
        })
        this.$emit('close')
      } catch (err: unknown) {
        if (err instanceof Error) {
          this.$buefy.toast.open({
            duration: 5000,
            message: err.message,
            position: 'is-bottom',
            type: 'is-danger'
          })
        }
      }
    }
  }

  public close () {
    this.$emit('close')
  }
}
