<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          Link Wonde
        </p>
        <button class="delete" aria-label="close" @click="closeView" />
      </header>
      <div ref="main" class="modal-card-body">
        <b-field>
          <b-input
            v-model="searchPhrase"
            placeholder="Search by postcode"
            type="search"
            icon-pack="fas"
            icon="search"
            expanded
            @keyup.native.enter="searchWondeSchools"
          />
          <p class="control">
            <button :class="{'button': true, 'is-link': true, 'is-loading':loading}" @click="searchWondeSchools">
              Search
            </button>
          </p>
        </b-field>
        <b-table
          v-if="schoolsData"
          :data.sync="schoolsData"
          :paginated="false"
          :mobile-cards="true"
          :striped="true"
          :selectable="false"
          :sort="false"
        >
          <template slot-scope="props">
            <b-table-column field="id" label="ID">
              <p class>
                {{ props.row.id }}<br>
                LA: {{ props.row.la_code }}<br>
                Est #: {{ props.row.establishment_number }}
              </p>
            </b-table-column>
            <b-table-column field="name" label="Name">
              <p>
                {{ props.row.name }}
              </p>
            </b-table-column>
            <b-table-column label="Address">
              <p>
                <span v-if="props.row.address.address_line_1">{{ props.row.address.address_line_1 }}<br></span>
                <span v-if="props.row.address.address_line_2">{{ props.row.address.address_line_2 }}<br></span>
                <span v-if="props.row.address.address_town">{{ props.row.address.address_town }}<br></span>
                <span v-if="props.row.address.address_postcode">{{ props.row.address.address_postcode }}</span>
              </p>
            </b-table-column>
            <b-table-column label="Audited">
              <b-icon v-if="props.row.meta.data.audited" type="is-success" icon="check" />
              <b-icon v-else type="is-danger" icon="close" />
            </b-table-column>
            <b-table-column label="Approved">
              <b-icon v-if="props.row.meta.data.approved" type="is-success" icon="check" />
              <b-icon v-else type="is-danger" icon="close" />
            </b-table-column>
            <b-table-column :key="props.row.id">
              <span v-if="props.row.existing_linked_school">
                Already Linked!<br>Org ID: {{ props.row.existing_linked_school }}
              </span>
              <button v-else-if="props.row.meta.data.approved" class="button is-dark" @click="linkSchool(props.row.id)">
                Link
              </button>
              <button v-else class="button is-dark" @click="linkSchool(props.row.id)">
                Request
              </button>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered content">
                <p>
                  <b-icon
                    custom-class="far"
                    pack="fa"
                    icon="frown"
                    size="is-large"
                  />
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
      <div class="modal-card-foot">
        <div>
          <b-checkbox v-model="onlyAudited" />Exclude Unapproved
        </div>
        <div>
          <button :disabled="!(meta && meta.pagination && meta.pagination.previous) || loading" class="button" @click="navigate(meta.pagination.previous)">
            <i class="icon fas fa-chevron-left" /><span>Previous</span>
          </button>
          <button class="button" :disabled="true" :class="{'is-loading': loading}" style="pointer-events:none">
            {{ (meta && meta.pagination && meta.pagination.current_page) || '1' }}
          </button>
          <button :disabled="!(meta && meta.pagination && meta.pagination.next) || loading" class="button" @click="navigate(meta.pagination.next)">
            <span>Next</span><i class="icon fas fa-chevron-right" />
          </button>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="closeView()" />
  </div>
</template>
<script>
import { request } from '@/edshed-common/api'

export default {
  name: 'LinkWonde',
  props: ['schoolId'],
  data (router) {
    return {
      schoolsData: null,
      meta: null,
      tabIndex: 0,
      searchPhrase: '',
      loading: false,
      onlyAudited: false
    }
  },
  mounted () {
    this.searchWondeSchools()
  },
  methods: {
    closeView () {
      this.$emit('close')
    },
    navigate (uri) {
      console.log(uri)
      this.loading = true
      request('GET', 'wonde/nextpage?uri=' + encodeURIComponent(uri), null)
        .then((response) => {
          this.loading = false
          const data = response.data
          this.meta = data.meta
          this.schoolsData = data.data
          if (this.$refs.main) {
            this.$refs.main.scrollTo({ top: 0 })
          }
          // this.searchDidChange()
          // console.log('readerData: ' + this.readerData)
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    searchWondeSchools () {
      this.loading = true
      request('GET', `wonde/schools/${this.onlyAudited ? 'approved' : 'all'}?postcode=${this.searchPhrase}`, null)
        .then((response) => {
          this.loading = false
          const data = response.data
          this.meta = data.meta
          this.schoolsData = data.data
          // this.searchDidChange()
          // console.log('readerData: ' + this.readerData)
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
        .finally(() => {
          this.loading = false
        })
    },
    linkSchool (wondeId) {
      if (this.schoolId && wondeId) {
        request('POST', 'schools/' + this.schoolId + '/setwondeid', { wondeid: wondeId })
          .then((response) => {
            const data = response.data
            console.log(data)
            this.closeView()
          })
          .catch((error) => {
            this.loading = false
            console.log(error)
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    }
  }
}
</script>

<style scoped>
  .modal-card {
    width: 800px;
  }

  .modal-card-foot {
    display: flex;
    justify-content: space-between;
  }
</style>
