




















































import { Api, StripeInvoiceAccountRegion, XeroContactInfo } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

@Component({

})
export default class XeroContactSearch extends Mixins(ComponentHelper) {
  @Prop({ required: true }) regionProp!: StripeInvoiceAccountRegion
  @Prop({ required: false, default: '' }) nameProp!: string
  @Prop({ required: false, default: '' }) accountProp!: string

  accountId: string = ''

  name: string = ''

  region: StripeInvoiceAccountRegion = 'GB'

  contacts: XeroContactInfo[] = []

  toastContainer = '#xero-contact-search-container'

  loading: boolean = false

  @Watch('regionProp', { immediate: true })
  regionPropChanged (val: StripeInvoiceAccountRegion) {
    this.region = val
  }

  @Watch('nameProp', { immediate: true })
  namePropChanged (val: string) {
    this.name = val
  }

  @Watch('accountProp', { immediate: true })
  accountPropChanged (val: string) {
    this.accountId = val
  }

  mounted () {
    this.searchContacts()
  }

  linkXeroContact (contact: XeroContactInfo) {
    this.$emit('link-contact', contact)
  }

  async searchContacts () {
    try {
      this.loading = true

      const contacts = await Api.getXeroContacts(this.region, {
        account_id: this.accountId !== '' ? this.accountId : undefined,
        name: this.name !== '' ? this.name : undefined
      })

      this.contacts = contacts
    } catch (err) {
      this.toastError('Could not load contacts')
    } finally {
      this.loading = false
    }
  }
}
