






















































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { CurriculumResourceDownload, CurriculumResourceDownloadFilters, ResourceRequiredPermissions, TableState } from '@/edshed-common/api/types'
import { request } from '@/edshed-common/api'
import { Mixins, Prop } from 'vue-property-decorator'
import ComponentHelper from '@/mixins/ComponentHelper'

@Component({
})
export default class ResourceDownloadsModal extends Mixins(ComponentHelper) {
  // Props
  @Prop({ default: undefined }) userId: number | undefined
  @Prop({ default: undefined }) schoolId: number | undefined

  ResourceRequiredPermissions = ResourceRequiredPermissions

  // Data members
  private results: CurriculumResourceDownload[] = []
  public total: number = 0
  public loading: boolean = false
  public displayMessage: string = 'Search a school or user ID to view results'

  // Get the current state of the table (will be used for pagination)
  private tableState: TableState = {
    page: 1,
    perPage: 50,
    sort: 'id',
    dir: 'asc',
    term: ''
  }

  // State of filter inputs
  private filterState: CurriculumResourceDownloadFilters = {
    userId: undefined,
    schoolId: undefined,
    from: new Date(2017, 0, 1),
    to: new Date(),
    availability: 'subscription',
    required_permission: 'any'
  }

  // Mounted
  public mounted (): void {
    this.$nextTick(() => {
      const { userId, schoolId } = this

      // Check if any parameters have been passed, if they have
      // load them in and search
      if (userId || schoolId) {
        this.filterState.userId = userId
        this.filterState.schoolId = schoolId
        this.requestData()
      }
    })
  }

  // Methods

  // Removes results and closes modal
  private closeModal (): void {
    this.results = []
    this.$emit('closed')
  }

  // API request for getResourceDownloads
  private async requestData () {
    // eslint-disable-next-line prefer-const
    let { userId, schoolId, ...filter } = this.filterState
    this.loading = true

    // Omit if empty
    if (userId) {
      filter = { userId, ...filter } as CurriculumResourceDownloadFilters
    }

    // Omit if empty
    if (schoolId) {
      filter = { schoolId, ...filter } as CurriculumResourceDownloadFilters
    }

    await request(
      'POST',
      'resources/downloads',
      filter
    )
      .then(({ data }) => {
        // If theres no results, show an according message
        if (!data.length) {
          this.displayMessage = 'No results found'
        }

        this.results = data
      })
      .catch((err) => {
        this.results = []
        this.displayMessage = err
      })

    this.loading = false
  }

  // Formats the dates on the table
  private formatDate (downloaded: string) : string {
    return downloaded.split('T')[0]
  }

  // End of methods

  // Computed

  // Check if the user is in a state of loading / have valid inputs
  get isDisabled (): boolean {
    return (!this.filterState.userId && !this.filterState.schoolId) || this.loading
  }
}
