

























import ComponentHelper from '@/mixins/ComponentHelper'
import { Api, StripeInvoiceAccountRegion } from '@/edshed-common/api'
import { Mixins, Prop, Component } from 'vue-property-decorator'

@Component({
  name: 'StripeInvoices'
})
export default class StripeInvoices extends Mixins(ComponentHelper) {
  @Prop({ required: true }) subscriptionId!: number

  invoices: object[] = []

  mounted () {
    this.loadInvoices()
  }

  async loadInvoices () {
    try {
      this.invoices = await Api.getStripeInvoicesForSubscription(this.subscriptionId)
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not load invoices',
        type: 'is-danger',
        position: 'is-bottom'
      })
    }
  }

  async restoreInvoice (id: string, region: StripeInvoiceAccountRegion) {
    try {
      await Api.restoreStripeInvoice(id, region)

      this.$buefy.toast.open({
        message: 'Finished',
        type: 'is-success',
        position: 'is-bottom'
      })
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not restore invoice',
        type: 'is-danger',
        position: 'is-bottom'
      })
    }
  }

  get isSuperDuperUser () {
    return this.$store.state.user.superduperuser === 1
  }
}
