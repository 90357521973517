import { render, staticRenderFns } from "./SchoolNotes.vue?vue&type=template&id=218b882a&scoped=true&"
import script from "./SchoolNotes.vue?vue&type=script&lang=ts&"
export * from "./SchoolNotes.vue?vue&type=script&lang=ts&"
import style0 from "./SchoolNotes.vue?vue&type=style&index=0&id=218b882a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "218b882a",
  null
  
)

export default component.exports