import { render, staticRenderFns } from "./UploadRemittanceModal.vue?vue&type=template&id=36ba15ca&scoped=true&"
import script from "./UploadRemittanceModal.vue?vue&type=script&lang=ts&"
export * from "./UploadRemittanceModal.vue?vue&type=script&lang=ts&"
import style0 from "./UploadRemittanceModal.vue?vue&type=style&index=0&id=36ba15ca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ba15ca",
  null
  
)

export default component.exports