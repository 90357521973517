<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-content box">
      <!-- Any other Bulma elements you want -->
      <div>
        <span class="content">
          <h1>Link District</h1>
        </span>
      </div>
      <b-field>
        <b-input
          v-model="searchPhrase"
          placeholder="Search..."
          type="search"
          icon-pack="fas"
          icon="search"
          expanded
          @keyup.native.enter="searchDidChange"
          @input="searchDidChange"
        />
        <p class="control">
          <button :class="{'button': true, 'is-link': true}" @click="searchDidChange">
            Search
          </button>
        </p>
      </b-field>
      <b-table
        v-if="filteredDistrictsData"
        :data.sync="filteredDistrictsData"
        :paginated="false"
        :mobile-cards="true"
        :striped="true"
        :selectable="false"
        :sort="false"
      >
        <template slot-scope="props">
          <b-table-column field="id" label="ID">
            #{{ props.row.id }}
          </b-table-column>
          <b-table-column field="name" label="Name">
            {{ props.row.school_name }}
          </b-table-column>
          <b-table-column field="id" label="Country">
            <span v-if="props.row.account_region === 'GB'">
              🇬🇧
            </span>
            <span v-if="props.row.account_region === 'US'">
              🇺🇸
            </span>
          </b-table-column>
          <b-table-column :key="props.row.id" label="Link">
            <button class="button is-dark" @click="linkSchool(props.row.id)">
              Link
            </button>
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered content">
              <p>
                <b-icon
                  custom-class="far"
                  pack="fa"
                  icon="frown"
                  size="is-large"
                />
              </p>
              <p>Nothing here.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="closeView()" />
  </div>
</template>
<script>
import { request } from '@/edshed-common/api'
export default {
  name: 'LinkDistrict',
  props: ['schoolId'],
  data (router) {
    return {
      districtsData: null,
      filteredDistrictsData: null,
      searchPhrase: ''
    }
  },
  mounted () {
    this.getDistricts()
  },
  methods: {
    linkSchool (districtId) {
      console.log('DISTRICT: ' + districtId)
      console.log('SCHOOL: ' + this.schoolId)
      request('POST', 'districts/' + districtId + '/linkschool', { schoolId: this.schoolId })
        .then((response) => {
          this.$buefy.toast.open({
            message: 'Saved',
            type: 'is-success'
          })
          this.closeView()
          // this.searchDidChange()
          // console.log('readerData: ' + this.readerData)
        })
        .catch((error) => {
          console.log(error)
          alert(error.message)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    closeView () {
      this.$emit('close')
    },
    getDistricts () {
      request('GET', 'districts', null)
        .then((response) => {
          const data = response.data
          this.districtsData = data.districts
          this.searchDidChange()
          // this.searchDidChange()
          // console.log('readerData: ' + this.readerData)
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    searchDidChange () {
      if (this.searchPhrase === '') {
        this.filteredDistrictsData = this.districtsData
      } else {
        this.filteredDistrictsData = this.districtsData.filter(s => s.school_name.toLowerCase().includes(this.searchPhrase.toLowerCase()))
      }
    }
  }
}
</script>
