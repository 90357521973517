











import { Subscription } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  name: 'EditSubscriptionPermissions'
})
export default class EditSubscriptionPermissions extends Mixins(ComponentHelper) {
  @Prop({ required: true }) sub!: Subscription
}
