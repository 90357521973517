






















import ComponentHelper from '@/mixins/ComponentHelper'
import { Api, StripeEventStub, StripeInvoiceAccountRegion } from '@/edshed-common/api'
import { Mixins, Prop, Component } from 'vue-property-decorator'

@Component({
  name: 'StripeEvents'
})
export default class StripeEvents extends Mixins(ComponentHelper) {
  @Prop({ required: true }) schoolId!: number

  events: StripeEventStub[] = []

  mounted () {
    this.loadEvents()
  }

  async loadEvents () {
    try {
      this.events = await Api.getStripeEventsForSchool(this.schoolId)
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not load Stripe events',
        type: 'is-danger',
        position: 'is-bottom'
      })
    }
  }

  async rerunEvent (id: string, region: StripeInvoiceAccountRegion) {
    try {
      await Api.rerunStripeEvent(id, region)

      this.$buefy.toast.open({
        message: 'Finished',
        type: 'is-success',
        position: 'is-bottom'
      })
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not rerun Stripe event',
        type: 'is-danger',
        position: 'is-bottom'
      })
    }
  }

  get isSuperDuperUser () {
    return this.$store.state.user.superduperuser
  }
}
